import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import JsonParser from "../../Utils/JsonParser";
import ParseQuery from "../../Utils/ParseQuery";
import * as Widget from "../../Components/Widget2";
import ProductImages from "../../Components/ProductImages";
import ProductInformation from "../../Components/ProductInformation";
import CartWidget from "../../Components/CartWidget";
import ProductPromotionSection from "../../Components/ProductPromotionSection";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      showSpinner: true
    };
  }

  componentDidMount() {
    let { productId, location, appActions, navActions } = this.props;
    this._fetchProductById(productId);

    if (location.search.indexOf("data") > -1) {
      let dataEncode = location.search.slice(location.search.indexOf("data"));
      let queryParams = ParseQuery(dataEncode);
      let data = JSON.parse(decodeURIComponent(queryParams.data));

      if (data.token) {
        window.localStorage.setItem("token", data.token);
        appActions.autoLogin().then(() => console.log("social login success"));
        let id = location.search.slice(0, location.search.indexOf("&"));
        setTimeout(() => navActions.push(`/product/${id}`), 1500);
      }
    }
  }

  componentDidUpdate() {
    let { productId } = this.props;
    let { item } = this.state;

    if (!item) {
      return;
    } else if (item && item.id != parseInt(productId)) {
      this._fetchProductById(productId);
    }
  }

  render() {
    let { item, showSpinner } = this.state;

    return (
      <Wrapper>
        <div className="product-breadcrumbs" />
        {!showSpinner ? (
          <>
            <section className="top">
              <div className="product-images">
                <ProductImages item={item} />
              </div>
              <div className="product-information">
                <ProductInformation item={item} />
              </div>
              <div className="cart-display">
                <CartWidget />
              </div>
            </section>
            <section className="bottom">
              <div className="product-description">
                <div
                  className="html-view"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            </section>
            <ProductPromotionSection
              title="相關商品"
              type={JsonParser(item.label)[0]}
              itemId={item.id}
            />
          </>
        ) : (
          <Widget.Row css="padding: 20px;" align="center" justify="center">
            <Widget.Spinner />
          </Widget.Row>
        )}
      </Wrapper>
    );
  }

  _fetchProductById = id => {
    let { appActions } = this.props;
    let { showSpinner } = this.state;

    if (!showSpinner) {
      this.setState({ showSpinner: true });
    }

    delay(500)
      .then(() => appActions.fetchProductById(id))
      .then(item => this.setState({ item }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  overflow-y: auto;

  & > section.top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 96px;
    width: 100%;
    min-height: 450px;
  }

  & > section.bottom {
    padding: 56px 150px 112px 150px;
    width: 100%;
    min-height: calc(100vh - 400px);
    background: #fafafa;
  }

  & .product-breadcrumbs {
  }

  & .product-images {
  }

  & .product-information {
    flex: 1;
    margin: 0px 58px 0px 45px;
  }

  & .product-description {
  }

  & .cart-display {
    max-width: 300px;
    width: 100%;
  }

  & .html-view {
    & h2 {
      margin-bottom: 16px;
      border-left: 6px solid #0a6fb7;
      padding-left: 8px;
      font-weight: 500;
      font-size: 16px;
      color: #0a6fb7;
    }

    & p {
      border-left: 1px solid #16233d;
      padding-left: 20px;
      white-space: pre-wrap;
      line-height: 24px;
      font-size: 14px;
      color: #16233d;

      :last-child {
        margin-bottom: 50px;
      }
    }

    & pre {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 1280px) {
    & > section.top {
      padding: 56px 70px;
    }

    & > section.bottom {
      padding: 56px 70px 112px 70px;
    }

    & .cart-display {
      display: none;
    }

    & .product-information {
      margin: 0px 0px 0px 45px;
    }
  }

  @media screen and (max-width: 768px) {
    & > section.top {
      padding: 56px 35px;
    }

    & > section.bottom {
      padding: 56px 35px 112px 35px;
    }

    & .product-information {
      margin: 0px 0px 0px 22px;
    }
  }

  @media screen and (max-width: 550px) {
    & > section.top {
      flex-direction: column;
      padding: 56px 20px;
    }
    & > section.bottom {
      padding: 56px 20px 112px 20px;
    }
    & .product-information {
      margin: 15px 0px 0px 0px;
      width: 100%;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      productId: Selectors.getQueryParams(ownProps).id
    }),
    ActionCreator
  )(ProductDetailPage)
);
