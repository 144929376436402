import React from "react";
import styled from "styled-components";

class ProductQuantityInput extends React.Component {
  render() {
    let { quantity, setQuantity } = this.props;
    return (
      <Wrapper>
        <Input
          type="number"
          value={quantity}
          onChange={e => setQuantity(e.target.value)}
          autoFocus
          placeholder="1"
        />
        <span>張</span>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #16233d;
  padding: 2px 10px;

  max-width: 166px;
  width: 100%;
  height: 35px;
  background: white;

  & > span {
    margin-left: 5px;
    font-size: 14px;
    color: #16233d;
  }
`;
const Input = styled.input`
  border: 0px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #16233d;

  :focus {
    outline: none;
  }
`;

export default ProductQuantityInput;
