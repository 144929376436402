import React, { Component } from "react";
import styled from "styled-components";
import QuantitySelector from "./ProductQuantitySelector";
import QuantityInput from "./ProductQuantityInput";
import AddToCartButton from "./AddToCartButton";
import BuyNowButton from "./BuyNowButton";

class ProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1
    };
  }

  render() {
    let { item } = this.props;
    let { quantity } = this.state;

    return (
      <Wrapper sale={item.special_price !== 0}>
        <div className="title">{item.name}</div>
        <div className="price-container">
          {item.original_price !== 0 && (
            <div className="special-price">${item.original_price}</div>
          )}
          <div className="orig-price">${item.price}</div>
        </div>
        <div className="quantity-container">
          <div className="label">數量</div>
          <QuantityInput
            quantity={quantity}
            setQuantity={q => {
              if (q === "" || parseInt(q) <= 0) {
                this.setState({ quantity: "" });
              } else {
                this.setState({ quantity: parseInt(q) });
              }
            }}
          />
        </div>
        {quantity > 1 && (
          <div className="expected-price-container">
            小計 ${item.price * quantity}
          </div>
        )}
        <div className="desp-container">
          {this._limitWords(item.description)}
        </div>
        <div className="actions-container">
          <AddToCartButton item={item} quantity={quantity} />
          <BuyNowButton
            item={item}
            quantity={quantity}
            css="margin-left: 15px;"
          />
        </div>
        <div className="share-container"></div>
      </Wrapper>
    );
  }

  _limitWords = desp => {
    return desp.slice(0, 90);
  };
}

const Wrapper = styled.div`
  & .title {
    margin-bottom: 20px;
    letter-spacing: 1.43px;
    font-weight: 500;
    font-size: 18px;
    color: #16233d;
  }

  & .price-container {
    margin-bottom: 20px;
    display: flex;

    & > .special-price {
      margin-right: 9px;
      min-width: 40px;
      letter-spacing: 1px;
      text-decoration: line-through;
      font-size: 14px;
      color: #b4b9c4;
    }

    & > .orig-price {
      min-width: 40px;
      letter-spacing: 1px;
      font-size: 14px;
      color: ${props => (props.sale ? "#D0021B" : "#16233D")};
    }
  }

  & .quantity-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    & > .label {
      margin-right: 12px;
      letter-spacing: 1px;
      font-size: 14px;
      color: #16233d;
    }
  }

  & .expected-price-container {
    margin-bottom: 5px;
    max-width: 300px;
    width: 100%;
    letter-spacing: 1px;
    font-size: 10px;
    color: gray;
  }

  & .desp-container {
    margin-bottom: 20px;
    white-space: pre-wrap;
    font-size: 14px;
  }

  & .actions-container {
    display: flex;
    align-items: center;
  }

  & .share-container {
  }

  @media screen and (max-width: 600px) {
    & .title {
      margin-bottom: 10px;
    }

    & .price-container {
      margin-bottom: 10px;
    }

    & .quantity-container {
      margin-bottom: 10px;
    }

    & .desp-container {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 550px) {
    & .desp-container {
      font-size: 14px;
    }
  }
`;

export default ProductInformation;
