import React, { Component } from "react";
import styled from "styled-components";

class QuantitySelector extends Component {
  render() {
    let { quantity, setQuantity } = this.props;
    return (
      <Wrapper value={quantity} onChange={e => setQuantity(e.target.value)}>
        {this._getQuantityRange().map((q, i) => (
          <option key={"quantity" + i} value={q}>
            {" "}
            {q} 張
          </option>
        ))}
      </Wrapper>
    );
  }

  _getQuantityRange = () => {
    let range = [];
    for (let i = 1; i < 21; i++) {
      range.push(i);
    }
    return range;
  };
}

const Wrapper = styled.select`
  border: 1px solid #16233d;
  max-width: 166px;
  width: 100%;
  height: 35px;
  background: white;

  :focus {
    outline: none;
  }
`;

export default QuantitySelector;
