import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import Login from "./Login";

class BuyNowButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginDialog: false,
      loading: false,
      pathname: ""
    };
  }

  componentDidMount() {
    let pathname = window.location.pathname + window.location.search;
    this.setState({ pathname });
  }

  render() {
    let { css } = this.props;
    let { showLoginDialog, loading, pathname } = this.state;

    return (
      <Wrapper css={css}>
        <Widget.LoadingWrapper loading={loading} spinnerSize={30}>
          <button onClick={() => this._addItemToCart()}>立即購買</button>
        </Widget.LoadingWrapper>

        {showLoginDialog && (
          <Login.Dialog
            pathname={pathname}
            onClose={() => this.setState({ showLoginDialog: false })}
          />
        )}
      </Wrapper>
    );
  }

  _addItemToCart = () => {
    let {
      profile,
      cartData,
      item,
      quantity,
      appActions,
      navActions
    } = this.props;

    if (!profile) {
      this.setState({ showLoginDialog: true });
      return;
    }

    if (!cartData || !cartData.items.find(i => i.product.id === item.id)) {
      this.setState({ loading: true });
      appActions
        .addItemToCart(item.id, JSON.stringify({ quantity }))
        .then(() => this.setState({ loading: false }))
        .then(() => navActions.push("/cart"))
        .catch(err => {
          console.warn(err);
          this.setState({ loading: false });
        });
    } else {
      navActions.push("/cart");
    }
  };
}

const Wrapper = styled.div`
  & button {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 17px;
    max-width: 166px;
    width: 100%;
    min-width: 100px;
    min-height: 34px;
    background: #0a6fb7;

    letter-spacing: 1px;
    font-size: 11px;
    color: white;
  }

  & button:focus {
    outline: none;
  }

  ${props => props.css || ""};
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(BuyNowButton);
