import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import Settings from "../../Domain/constants";
import JsonParser from "../../Utils/JsonParser";
import * as Icon from "../Icon";

class ProductImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    let { item } = this.props;
    let { slideIndex } = this.state;

    return (
      <Wrapper>
        <div className="box-container">
          {item.image && (
            <ImageBox
              onClick={() => this._updateSlideIndex(0)}
              img={`${Settings.apiUrl}${item.image}`}
              css="margin-bottom: 10px;"
            />
          )}
          {item.images &&
            this._filterImagesQuantity(item.images).map((ele, i) => (
              <ImageBox
                onClick={() => this._updateSlideIndex(i + 1)}
                key={"box-item" + i}
                img={`${Settings.apiUrl}.${ele.image}`}
                css="margin-bottom: 10px;"
              />
            ))}
          {this._getVideoProperty(item) && (
            <ImageBox
              onClick={() => this._updateSlideIndex(3)}
              css={`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <Icon.PlayCircleOutline size={30} color="#16233d" />
              VIDEO
            </ImageBox>
          )}
        </div>

        <div className="carousel-container">
          <Carousel
            width={`${this._getRwdSize()}px`}
            initialSlideHeight={this._getRwdSize()}
            renderBottomCenterControls={() => false}
            renderCenterLeftControls={this._renderLeftArrow}
            renderCenterRightControls={this._renderRightArrow}
            slideIndex={slideIndex}
            afterSlide={this._updateSlideIndex}
          >
            {item.image && (
              <SlideItem img={`${Settings.apiUrl}${item.image}`}>
                <div className="image" />
              </SlideItem>
            )}
            {item.images &&
              this._filterImagesQuantity(item.images).map((ele, i) => (
                <SlideItem
                  key={"slide-item" + i}
                  img={`${Settings.apiUrl}.${ele.image}`}
                >
                  <div className="image" />
                </SlideItem>
              ))}
            {this._getVideoProperty(item) && (
              <SlideItem>
                <ReactPlayer
                  url={item.video_link}
                  controls={true}
                  playing={slideIndex === 3 ? true : false}
                  width={"100%"}
                  height={"334px"}
                  onEnded={() =>
                    setTimeout(() => this.setState({ slideIndex: 0 }), 800)
                  }
                />
              </SlideItem>
            )}
          </Carousel>
          {this._getVideoProperty(item) && (
            <div className="button-container">
              <button onClick={() => this.setState({ slideIndex: 3 })}>
                播放影片
              </button>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }

  _getRwdSize = () => {
    let { mounted } = this.state;

    if (mounted) {
      if (window.screen.width === 768) {
        return 260;
      } else if (window.screen.width === 600) {
        return 230;
      } else if (window.screen.width <= 340) {
        return 280;
      }
    }

    return 334;
  };

  _renderLeftArrow = ({ previousSlide }) => {
    let { slideIndex } = this.state;

    if (slideIndex === 3) {
      return;
    }

    return (
      <IconContainer
        onClick={
          slideIndex === 0
            ? () => this.setState({ slideIndex: 2 })
            : previousSlide
        }
        css="margin-left: 3px;"
      >
        <Icon.KeyboardArrowLeft
          color="#d6d6d6"
          style={{ width: 25, height: 25 }}
        />
      </IconContainer>
    );
  };

  _renderRightArrow = ({ nextSlide }) => {
    let { slideIndex } = this.state;

    if (slideIndex === 3) {
      return;
    }

    return (
      <IconContainer
        onClick={
          slideIndex === 2 ? () => this.setState({ slideIndex: 0 }) : nextSlide
        }
        css="margin-right: 3px;"
      >
        <Icon.KeyboardArrowRight
          color="#d6d6d6"
          style={{ width: 25, height: 25 }}
        />
      </IconContainer>
    );
  };

  _updateSlideIndex = slideIndex => {
    this.setState({ slideIndex });
  };

  _getVideoProperty = item => {
    let result = JsonParser(item.data);
    if (result.video_link) {
      item["video_link"] = result.video_link;
      return item;
    } else {
      return null;
    }
  };

  _filterImagesQuantity = images => {
    return images.filter((_, i) => i < 2);
  };
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 440px;
  width: 100%;

  & .box-container {
    margin-right: 30px;
    width: 76px;
    height: 334px;
  }

  & .carousel-container {
  }

  & .button-container {
    display: none;

    & > button {
      margin-top: 5px;
      max-width: 100%;
      width: 100%;
      min-height: 30px;
      background: #0a6fb7;
      letter-spacing: 2px;
      font-size: 12px;
      color: white;
      cursor: pointer;
      position: relative;
      border: 0px;

      :focus {
        outline: none;
      }
    }
  }

  @media screen and (max-width: ${1024 - 1}px) {
    & .box-container {
      display: none;
    }

    & .button-container {
      display: block;
    }
  }
`;

const ImageBox = styled.div`
  cursor: pointer;
  border: 1px solid #d6d6d6;
  width: 76px;
  height: 76px;

  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${props => props.css || ""};
`;

const SlideItem = styled.div`
  border: 1px solid #d6d6d6;
  width: 334px;
  height: 334px;

  & > .image {
    width: 100%;
    height: 100%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    width: 260px;
    height: 260px;
  }

  @media screen and (max-width: 600px) {
    width: 230px;
    height: 230px;
  }

  @media screen and (max-width: 550px) {
    width: 334px;
    height: 334px;
  }

  @media screen and (max-width: 340px) {
    width: 280px;
    height: 280px;
  }

  ${props => props.css || ""};
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #d6d6d6;
  border-radius: 50%;
  background-color: transparent;
  width: 30px;
  height: 30px;
  :focus {
    outline: none;
  }

  ${props => props.css || ""};
`;

export default ProductImages;
